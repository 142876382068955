import React from 'react';
import { Provider } from 'react-redux';
import store from './reducers';

import Header from './components/Header';
import Resultado from './components/Resultado';
import Info from './components/Info';
import Locations from './components/Locations';
import Footer from './components/Footer';

import './App.scss';

const App = () => {

  return (
    <Provider store={store}>
        <div className="app">
          <Header />
          <Resultado />
          <Info />
          <Locations />
          <Footer />
        </div>
    </Provider>
  )
};

export default App;
