import React from 'react';
import InlineSVG from 'svg-inline-react';

const SocialButtons = (props) => {

  const getItem = (link, svg) => {

    const item =
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <span>
                    <InlineSVG src={svg}/>
                  </span>
                </a>;

    return item;

  }

  const svgSourceInstagram = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="translate(-0.005 -0.005)"><path d="M7.053,23.928A8.866,8.866,0,0,1,4.14,23.37a5.9,5.9,0,0,1-2.126-1.384A5.88,5.88,0,0,1,.63,19.86a8.853,8.853,0,0,1-.558-2.912C.014,15.653,0,15.224,0,12S.014,8.347.072,7.053A8.853,8.853,0,0,1,.63,4.14,5.88,5.88,0,0,1,2.014,2.014,5.88,5.88,0,0,1,4.14.63,8.853,8.853,0,0,1,7.053.072C8.347.014,8.775,0,12,0s3.653.014,4.948.072A8.853,8.853,0,0,1,19.86.63a5.88,5.88,0,0,1,2.126,1.384A5.9,5.9,0,0,1,23.37,4.14a8.866,8.866,0,0,1,.557,2.913C23.986,8.331,24,8.757,24,12s-.014,3.669-.072,4.948a8.865,8.865,0,0,1-.557,2.912,6.138,6.138,0,0,1-3.51,3.51,8.865,8.865,0,0,1-2.912.557c-1.279.058-1.7.072-4.948.072S8.331,23.986,7.053,23.928Zm.1-21.7a6.67,6.67,0,0,0-2.228.413,3.733,3.733,0,0,0-1.38.9,3.733,3.733,0,0,0-.9,1.38,6.67,6.67,0,0,0-.413,2.228C2.174,8.4,2.162,8.78,2.162,12s.012,3.6.07,4.849a6.67,6.67,0,0,0,.413,2.228,3.733,3.733,0,0,0,.9,1.38,3.733,3.733,0,0,0,1.38.9,6.64,6.64,0,0,0,2.228.413c1.252.058,1.629.07,4.849.07s3.6-.012,4.849-.07a6.64,6.64,0,0,0,2.228-.413,3.975,3.975,0,0,0,2.278-2.278,6.64,6.64,0,0,0,.413-2.228c.058-1.252.07-1.629.07-4.849s-.012-3.6-.07-4.849a6.64,6.64,0,0,0-.413-2.228,3.733,3.733,0,0,0-.9-1.38,3.733,3.733,0,0,0-1.38-.9,6.67,6.67,0,0,0-2.228-.413C15.6,2.174,15.22,2.162,12,2.162S8.4,2.174,7.151,2.232ZM5.838,12A6.162,6.162,0,1,1,12,18.162,6.169,6.169,0,0,1,5.838,12ZM8,12a4,4,0,1,0,4-4A4.005,4.005,0,0,0,8,12Zm8.966-6.405a1.44,1.44,0,1,1,1.439,1.439A1.44,1.44,0,0,1,16.966,5.595Z" transform="translate(0.005 0.005)"/></g></svg>';
  const svgSourceFacebook = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22.676,0H1.324A1.324,1.324,0,0,0,0,1.324V22.676A1.324,1.324,0,0,0,1.324,24h11.5V14.719H9.7V11.086h3.124V8.412c0-3.1,1.9-4.788,4.659-4.788a26.565,26.565,0,0,1,2.789.141v3.24h-1.9c-1.506,0-1.8.712-1.8,1.763v2.313h3.6L19.7,14.714H16.552V24h6.124A1.324,1.324,0,0,0,24,22.676V1.324A1.324,1.324,0,0,0,22.676,0Z" transform="translate(0 0)"/></svg>';
  const svgSourceFlickr = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="translate(-14.285 -128.368)"><rect width="24" height="24" transform="translate(14.285 128.368)" fill="rgba(255,255,255,0)"/><path d="M28.285,146.627a5.5,5.5,0,1,1-5.5-5.5A5.5,5.5,0,0,1,28.285,146.627Z" transform="translate(-3 -5.759)"/><path d="M267.269,142.736a4.6,4.6,0,1,1-4.6-4.6A4.607,4.607,0,0,1,267.269,142.736Zm-4.6-5.5a5.5,5.5,0,1,0,5.5,5.5A5.505,5.505,0,0,0,262.667,137.237Z" transform="translate(-229.882 -1.869)"/></g></svg>';
  const svgSourceSite =  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect width="24" height="24" fill="rgba(255,255,255,0)"/><path d="M21.517,2.553l-.09-.09a5.236,5.236,0,0,0-7.365,0L9.392,7.151a5.22,5.22,0,0,0,0,7.356l.09.09a3.684,3.684,0,0,0,.539.471l1.707-1.7a3.367,3.367,0,0,1-.561-.426l-.09-.09a2.8,2.8,0,0,1,0-3.969l4.693-4.687a2.808,2.808,0,0,1,3.974,0l.09.09a2.8,2.8,0,0,1,0,3.969l-2.111,2.108a7.278,7.278,0,0,1,.516,2.848l3.278-3.274A5.206,5.206,0,0,0,21.517,2.553ZM14.6,9.3a5.776,5.776,0,0,0-.539-.471l-1.707,1.7a3.367,3.367,0,0,1,.561.426l.09.09a2.8,2.8,0,0,1,0,3.969L8.314,19.732a2.808,2.808,0,0,1-3.974,0l-.09-.09a2.8,2.8,0,0,1,0-3.969l2.111-2.108a7.278,7.278,0,0,1-.516-2.848L2.566,13.991a5.22,5.22,0,0,0,0,7.356l.09.09a5.236,5.236,0,0,0,7.365,0l4.693-4.687a5.22,5.22,0,0,0,0-7.356Z" transform="translate(-0.05 0.05)"/></svg>';
  const svgSourceYoutube = '<svg xmlns="http://www.w3.org/2000/svg" width="37.556" height="26" viewBox="0 0 37.556 26"><g transform="translate(-17.5 -27.5)"><path d="M48.7,27.5H23.856A6.356,6.356,0,0,0,17.5,33.856V47.144A6.356,6.356,0,0,0,23.856,53.5H48.7a6.356,6.356,0,0,0,6.356-6.356V33.856A6.356,6.356,0,0,0,48.7,27.5ZM43.633,40.754,32.366,46.532a.289.289,0,0,1-.422-.254V34.722a.289.289,0,0,1,.422-.254l11.267,5.778a.289.289,0,0,1,0,.514Z" /></g></svg>';

  let instagram = props.instagram ? getItem(props.instagram, svgSourceInstagram) : "";
  let facebook = props.facebook ? getItem(props.facebook, svgSourceFacebook) : "";
  let flickr = props.flickr ? getItem(props.flickr, svgSourceFlickr) : "";
  let site = props.site ? getItem(props.site, svgSourceSite) : "";
  let youtube = props.youtube ? getItem(props.youtube, svgSourceYoutube) : "";

  return (
    <div className="social">

      <svg id="gradient" aria-hidden="true" focusable="false">
        <linearGradient id="my-share-gradient" x2="1" y2="1">
          <stop offset="0%" stopColor="#FFD62C" />
          <stop offset="100%" stopColor="#EB6625" />
        </linearGradient>
      </svg>

      {instagram}
      {facebook}
      {flickr}
      {site}
      {youtube}

    </div>
  );

};

export default SocialButtons;
