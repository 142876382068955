import React from 'react';

import SocialButtons from '../SocialButtons';
import InlineSVG from 'svg-inline-react';

const svgSource = '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="83.926" viewBox="0 0 50 83.926"><g transform="translate(0.022 0.035)"><g transform="translate(29.098 -0.035)"><path d="M87.16,13.853S75.73,6.127,74.981,5.536a3.6,3.6,0,0,1-1.1-2.247,2.964,2.964,0,0,1,.67-2.207,3.127,3.127,0,0,1,2.128-1.1,5.06,5.06,0,0,1,1.774.2c.276.118,14.622,10.05,14.622,10.05h-.079a3.729,3.729,0,0,1,1.734,2.6,3.621,3.621,0,0,1-.749,2.444L83.612,29.894a4.54,4.54,0,0,1-1.813.631,3.177,3.177,0,0,1-2.325-.631,2.664,2.664,0,0,1-1.1-1.971,4.429,4.429,0,0,1,.552-2.4Z" transform="translate(-73.862 0.035)" fill="#fff"/></g><g transform="translate(20.152 6.547)"><path d="M58.626,26.611s-5.557-3.784-6.346-4.375a3.6,3.6,0,0,1-1.1-2.247,2.964,2.964,0,0,1,.67-2.207,3.136,3.136,0,0,1,2.128-1.1,5.06,5.06,0,0,1,1.774.2c.276.118,8.789,6.07,8.789,6.07H64.46a3.729,3.729,0,0,1,1.734,2.6,3.621,3.621,0,0,1-.749,2.444L59.1,37.056a4.54,4.54,0,0,1-1.813.631,3.177,3.177,0,0,1-2.325-.631,2.664,2.664,0,0,1-1.1-1.971,4.429,4.429,0,0,1,.552-2.4Z" transform="translate(-51.162 -16.665)" fill="#fff"/></g><path d="M19.5,114.888a3.8,3.8,0,0,1,3.823,4.769l-.67,2.877a2.362,2.362,0,0,1-2.286,1.971H11.692C3.731,124.5-1.393,118.041.3,110.079l8.75-41.148A2.326,2.326,0,0,1,11.338,67h5.754a1.576,1.576,0,0,1,1.537,1.931L17.013,76.3h8.671a3.827,3.827,0,0,1,3.823,4.848l-.591,2.877a2.567,2.567,0,0,1-2.365,1.931H15L9.919,110a3.827,3.827,0,0,0,3.823,4.848H19.5Z" transform="translate(0 -40.614)" fill="#fff"/></g></svg>';

const Footer = () => (

  <footer className="footer">
    <div className="container">

      <div className="logo">
        <a href="https://www.bhbus.com.br/" target="_blank" rel="noopener noreferrer">
          <span><InlineSVG src={svgSource} /></span>
        </a>

      </div>

      <div className="links">
        <h3 className="center uppercase">Transfácil</h3>
        <ul>
          <li>
            <a href="https://www.bhbus.com.br/institucional/quem-somos" target="_blank" rel="noopener noreferrer">Quem somos</a>
          </li>
          <li>
            <a href="https://www.bhbus.com.br/institucional/ouvidoria" target="_blank" rel="noopener noreferrer">Ouvidoria</a>
          </li>
          <li>
            <a href="https://www.bhbus.com.br/institucional/canais-de-venda" target="_blank" rel="noopener noreferrer">Canais de venda</a>
          </li>
        </ul>
      </div>

      <div className="container-social">
        <SocialButtons instagram="https://www.instagram.com/transfaciloficial/" facebook="https://pt-br.facebook.com/oficialtransfacil" youtube="https://www.youtube.com/watch?v=XLhkHB61xAw" />
      </div>
    </div>
  </footer>

);

export default Footer;
