import React from 'react';
import InlineSVG from 'svg-inline-react';

const Place = (props) => {

  const svgSourcePin = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="translate(-25 -623)"><rect width="24" height="24" transform="translate(25 623)" fill="rgba(247,247,247,0)"/><path d="M6.667,0A6.92,6.92,0,0,0,0,7.16C0,11,6.108,19.48,6.369,19.84a.449.449,0,0,0,.3.16.331.331,0,0,0,.3-.16c.261-.36,6.369-8.84,6.369-12.68A6.92,6.92,0,0,0,6.667,0Zm0,10.4A3.121,3.121,0,0,1,3.65,7.16,3.121,3.121,0,0,1,6.667,3.92,3.121,3.121,0,0,1,9.683,7.16,3.121,3.121,0,0,1,6.667,10.4Z" transform="translate(30 625)" fill="#fff"/></g></svg>';

  return (
    <div className="place">
      <a href={props.link} target="_blank" rel="noopener noreferrer" className="uppercase">
        <InlineSVG src={svgSourcePin}/> {props.title}
      </a>
    </div>
  )
};

export default Place;
