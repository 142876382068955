import { createStore } from 'redux';
import { SET_VOTO1, SET_VOTO2, SET_VOTO3 } from "../constants/action-types";

const INITIAL_STATE = {
  voto1: 0,
  voto2: 0,
  voto3: 0
}

function reducer(state = INITIAL_STATE, action) {

  if(action.type === SET_VOTO1){
    return { ...state, voto1: action.payload }
  }

  if(action.type === SET_VOTO2){
    return { ...state, voto2: action.payload }
  }

  if(action.type === SET_VOTO3){
    return { ...state, voto3: action.payload }
  }

  return state;
}

const rootReducer = createStore(reducer);

// for debug - store.getState();
window.store = rootReducer;

export default rootReducer;
