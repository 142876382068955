import React from 'react';

import niloProfile from '../../assets/images/nilo.png';
import raquelProfile from '../../assets/images/raquel.png';
import marcusProfile from '../../assets/images/marcus.png';

import niloCartao from '../../assets/images/cartao_nilo.png';
import raquelCartao from '../../assets/images/cartao_raquel.png';
import marcusCartao from '../../assets/images/cartao_marcus.png';

const Resultado = (props) => {

  return (

    <section className="results">
      <div className="container">

        <div name="artista1">
          <div className="itens">
            <div className="artist">
              <div className="cards-container">
                <div className="item">
                  <div className="card-radio-button">
                    <label htmlFor="1">
                      <div className="content">
                        <picture className="animated-background">
                          <img src={niloCartao} alt=""/>
                        </picture>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="profile mt-2">
                  <picture className="animated-background">
                    <img src={niloProfile} alt="Nilo Zack" />
                  </picture>
                  <h5 className="name">Nilo Zack</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div name="artista2">
          <div className="itens">
            <div className="artist">
              <div className="cards-container">
                <div className="item">
                  <div className="card-radio-button">
                    <label htmlFor="1">
                      <div className="content">
                        <picture className="animated-background">
                          <img src={raquelCartao} alt=""/>
                        </picture>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="profile mt-2">
                  <picture className="animated-background">
                    <img src={raquelProfile} alt="Raquel Bolinho" />
                  </picture>
                  <h5 className="name">Raquel Bolinho</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div name="artista3">
          <div className="itens">
            <div className="artist">
              <div className="cards-container">
                <div className="item">
                  <div className="card-radio-button">
                    <label htmlFor="1">
                      <div className="content">
                        <picture className="animated-background">
                          <img src={marcusCartao} alt=""/>
                        </picture>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="profile mt-2">
                  <picture className="animated-background">
                  < img src={marcusProfile} alt="Marcus Paschoalin" />
                  </picture>
                  <h5 className="name">Marcus Paschoalin</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <h4 className="center uppercase mt-6 ml-auto mr-auto">
          Mais práticos, mais seguros e muito mais bonitos.
      </h4>

    </section>

  );
}

export default Resultado;
