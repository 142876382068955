import React from 'react';
//import SocialButtons from '../SocialButtons';
import InlineSVG from 'svg-inline-react';

const Info = () => {

  const svgWhatsapp = `<svg enable-background="new 0 0 58 58" viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg"><path d="m0 58 4.988-14.963c-2.531-4.257-3.988-9.225-3.988-14.537 0-15.74 12.76-28.5 28.5-28.5s28.5 12.76 28.5 28.5-12.76 28.5-28.5 28.5c-4.789 0-9.299-1.187-13.26-3.273z" fill="#2cb742"/><path d="m47.683 37.985c-1.316-2.487-6.169-5.331-6.169-5.331-1.098-.626-2.423-.696-3.049.42 0 0-1.577 1.891-1.978 2.163-1.832 1.241-3.529 1.193-5.242-.52l-3.981-3.981-3.981-3.981c-1.713-1.713-1.761-3.41-.52-5.242.272-.401 2.163-1.978 2.163-1.978 1.116-.627 1.046-1.951.42-3.049 0 0-2.844-4.853-5.331-6.169-1.058-.56-2.357-.364-3.203.482l-1.758 1.758c-5.577 5.577-2.831 11.873 2.746 17.45l5.097 5.097 5.097 5.097c5.577 5.577 11.873 8.323 17.45 2.746l1.758-1.758c.846-.848 1.041-2.147.481-3.204z" fill="#fff"/></svg>`;

  const svgFacebook = `<svg enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m448 0h-384c-35.296 0-64 28.704-64 64v384c0 35.296 28.704 64 64 64h384c35.296 0 64-28.704 64-64v-384c0-35.296-28.704-64-64-64z" fill="#1976d2"/><path d="m432 256h-80v-64c0-17.664 14.336-16 32-16h32v-80h-64c-53.024 0-96 42.976-96 96v64h-64v80h64v176h96v-176h48z" fill="#fafafa"/></svg>`;

  return (
    <section className="info">
      <div className="container">
        <h3 className="title center uppercase ml-auto mr-auto">
          Escolha seu cartão e receba em casa.
        </h3>

        <h3 className="center uppercase ml-auto mr-auto">
          Compre:
        </h3>

        <div className="info-redes mt-7">

          <a className="info-rede" href="https://www.facebook.com/messages/t/oficialtransfacil" rel="noopener noreferrer" target="_blank">
            <InlineSVG src={svgFacebook} />
            <span>Via Facebook</span>
          </a>

          <a className="info-rede" href="https://api.whatsapp.com/send?phone=553132487300&text=&source=&data=&app_absent=" rel="noopener noreferrer" target="_blank">
            <InlineSVG src={svgWhatsapp} />
            <span>Via Whatsapp</span>
          </a>

        </div>

      </div>

      <div className="container box">

        <h3 className="title center uppercase ml-auto mr-auto">
          Saiba em qual terminal encontrar seu cartão preferido.
        </h3>

        <a className="button mt-6" rel="noopener noreferrer" href="https://www.bhbus.com.br/assets/files/planilha_cartoes_especiais_ATMs.pdf" target="_blank"><span>Clique e confira</span></a>

      </div>

    </section>
  );

}

export default Info;
