import React from 'react';

import Place from '../Place';

const Locations = (props) => (
  <section className="locations">
    <div className="container">
      <h3 className="uppercase center">Encontre também nos pontos de venda abaixo:</h3>
    </div>
    <div className="container">
      <h3 className="uppercase center -small">estações de transferência</h3>
      <div className="container-locations -border-bottom">
        <div className="item">
          <h4 className="uppercase">Antônio Carlos</h4>
          <Place title="senai" link="https://goo.gl/maps/yLxLRZci7dJNcQEz7" />
          <Place title="odilon behrens" link="https://goo.gl/maps/rcAurmMQsbbfAQiu5" />
          <Place title="iapi" link="https://goo.gl/maps/Sqoz2UcsAj3m55F19" />
          <Place title="operários" link="https://goo.gl/maps/JUSqfqpBJQFJRBi99" />
          <Place title="aparecida" link="https://goo.gl/maps/WsiMr3cHR3eY8paT7" />
          <Place title="américo vespúcio" link="https://goo.gl/maps/uTnGk5XtxJ3Yv1nQ6" />
          <Place title="cachoeirinha" link="https://goo.gl/maps/JwH6HG7zvsVyVEPZ9" />
          <Place title="são francisco" link="https://goo.gl/maps/zShGXaVMpfnAG7qV6" />
          <Place title="colégio militar" link="https://goo.gl/maps/cf1KacjZzad9AUvt9" />
          <Place title="liberdade" link="https://goo.gl/maps/hmXpiHL5ukJanMzf7" />
          <Place title="ufmg" link="https://goo.gl/maps/RwJi5ZcFQXNQv325A" />
          <Place title="mineirão" link="https://goo.gl/maps/Y8KCsYdgSh8fMtv16" />
          <Place title="santa rosa" link="https://goo.gl/maps/tYVPfjpbU5DQi5Vg9" />
        </div>
        <div className="item">
          <h4 className="uppercase">Cristiano Machado</h4>
          <Place title="minas shopping" link="https://goo.gl/maps/Mtbd5oZQoQQtCahb8" />
          <Place title="ouro minas" link="https://goo.gl/maps/rnq1NctDwuzc63As9" />
          <Place title="união" link="https://goo.gl/maps/AUYYBHTG7nGmpVN67" />
          <Place title="ipiranga" link="https://goo.gl/maps/iVtydjxmrr8EAWLR7" />
          <Place title="cidade nova" link="https://goo.gl/maps/d2MJQw7K4D8PFSKR9" />
          <Place title="feira dos produtores" link="https://goo.gl/maps/qpDHtZeursVAjkjE6" />
          <Place title="são judas tadeu" link="https://goo.gl/maps/a3wrBRv2rduy2EQaA" />
          <Place title="sagrada família" link="https://goo.gl/maps/DJdU5kSFU6FAS4ia7" />
          <Place title="silviano brandão" link="https://goo.gl/maps/VZY5F6dr7tM8KFNt6" />
        </div>
        <div className="item">
          <h4 className="uppercase">Corredor Pedro I</h4>
          <Place title="monte castelo" link="https://goo.gl/maps/qg6biB3XYgAWi3is8" />
          <Place title="montese" link="https://goo.gl/maps/TnJjZzkfKfEcehxx6" />
          <Place title="lagoa do nado" link="https://goo.gl/maps/vt52p1X1duR74SE77" />
          <Place title="são joão batista" link="https://goo.gl/maps/GPi6y7SuYaWM1ji48" />
          <Place title="planalto" link="https://goo.gl/maps/oKzEAvoLWrFeccAN8" />
          <Place title="cristiano guimarães" link="https://goo.gl/maps/7YwYYHF7TzZtrLgaA" />
        </div>
        <div className="item">
          <h4 className="uppercase">CORREDOR VILARINHO</h4>
          <Place title="candelária" link="https://goo.gl/maps/4zZtrpjxF8gcpeaQ7" />
          <Place title="minas caixa" link="https://goo.gl/maps/PCS6nnDyi49iPkwg9" />
          <Place title="quadras do vilarinho" link="https://goo.gl/maps/paDg1gYwnAxPBzbn7" />
          <Place title="upa venda nova" link="https://goo.gl/maps/aXB6Wxf2XsW6Mnnu9" />
        </div>
      </div>
    </div>

    <div className="container">
      <div className="container-locations">
        <div className="item">
          <h4 className="uppercase">Terminais</h4>
          <Place title="venda nova" link="https://goo.gl/maps/2YVTqdtcnEt5DzXC7" />
          <Place title="vilarinho" link="https://goo.gl/maps/sQzd1RXvVujQJc5i7" />
          <Place title="pampulha" link="https://goo.gl/maps/ZfUoNAB3m1tBrznV7" />
          <Place title="são gabriel" link="https://goo.gl/maps/6SzTqkGca1s4HmpT9" />
          <Place title="barreiro" link="https://goo.gl/maps/BmAsbu6CYaKGZAGFA" />
          <Place title="diamante" link="https://goo.gl/maps/7EvfEXVHDfX5Eu4B7" />
        </div>
        <div className="item">
          <h4 className="uppercase">Quiosques</h4>
          <Place title="carijós" link="https://goo.gl/maps/BVPLZt496FrVrWnF6" />
          <Place title="tupinambás" link="https://goo.gl/maps/4ogi3URRGMix6M6t6" />
          <Place title="rio de janeiro" link="https://goo.gl/maps/Y2U7ZVeXonfQ3P438" />
          <Place title="são paulo" link="https://goo.gl/maps/ov58SQAjKqEYepoe9" />
        </div>
        <div className="item">
          <h4 className="uppercase">Postos</h4>
          <Place title="floresta" link="https://goo.gl/maps/ZoiF15AkzBK9VAJJ7" />
          {/* <Place title="montese" link="https://goo.gl/maps/TnJjZzkfKfEcehxx6" />
          <Place title="lagoa do nado" link="https://goo.gl/maps/rX24ZYxahD5nYocQ9" />
          <Place title="são joão batista" link="https://goo.gl/maps/58hyUrebGtPU4NLx7" />
          <Place title="planalto" link="https://goo.gl/maps/FTHuk1up8F6rpa3B7" />
          <Place title="cristiano guimarães" link="https://goo.gl/maps/p9Q7mmUuS1Hmf9tA8" /> */}
        </div>
        <div className="item">
          <h4 className="uppercase">CBTU</h4>
          <Place title="Metrô BH" link="https://goo.gl/maps/dkwc9CTnih55GyHp6" />
        </div>
      </div>
    </div>
  </section>
);

export default Locations;
