import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';

Sentry.init({
  dsn: "https://ac8b4c53147b47d2ae2018cb06993952@sentry.io/1855564",
  environment:  process.env.REACT_APP_ENV
});

ReactDOM.render(
  <App />
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
